import { default as _91_46_46_46slug_93WSLSvWJwb1Meta } from "/var/www/domains/content/pages/[...slug].vue?macro=true";
import { default as _400ZA9i15nQxAMeta } from "/var/www/domains/core/pages/400.vue?macro=true";
import { default as _401N8cQ5zacU3Meta } from "/var/www/domains/core/pages/401.vue?macro=true";
import { default as _403Sa7PZc6krdMeta } from "/var/www/domains/core/pages/403.vue?macro=true";
import { default as _404eylsxcW4sLMeta } from "/var/www/domains/core/pages/404.vue?macro=true";
import { default as _500W3ZJe4VA6ZMeta } from "/var/www/domains/core/pages/500.vue?macro=true";
import { default as about_45us4RruZM0m8HMeta } from "/var/www/brands/timberland/pages/about-us.vue?macro=true";
import { default as add_45billing_45addressky4baLy4VQMeta } from "/var/www/domains/commerce/pages/account/address/add-billing-address.vue?macro=true";
import { default as add_45shipping_45address1u0lx6UGlGMeta } from "/var/www/domains/commerce/pages/account/address/add-shipping-address.vue?macro=true";
import { default as indexB51Ugf957PMeta } from "/var/www/domains/commerce/pages/account/address/index.vue?macro=true";
import { default as addryMI6DLVkOMeta } from "/var/www/domains/commerce/pages/account/credit-cards/add.vue?macro=true";
import { default as index3o4cqghtCKMeta } from "/var/www/domains/commerce/pages/account/credit-cards/index.vue?macro=true";
import { default as gift_45cardstNcmnAXkqFMeta } from "/var/www/domains/commerce/pages/account/gift-cards.vue?macro=true";
import { default as index4G0LVOmYUUMeta } from "/var/www/domains/commerce/pages/account/index.vue?macro=true";
import { default as loyaltye4NDp5ukrDMeta } from "/var/www/domains/commerce/pages/account/loyalty.vue?macro=true";
import { default as _91_46_46_46id_93mxqHszQSfiMeta } from "/var/www/domains/commerce/pages/account/order-details/[...id].vue?macro=true";
import { default as order_45historyqA4XphvWAYMeta } from "/var/www/domains/commerce/pages/account/order-history.vue?macro=true";
import { default as profileECAuXzYlINMeta } from "/var/www/domains/commerce/pages/account/profile.vue?macro=true";
import { default as account8JQgS7IJWSMeta } from "/var/www/domains/commerce/pages/account.vue?macro=true";
import { default as _91slug_935Q04msOnAlMeta } from "/var/www/domains/commerce/pages/articles/[slug].vue?macro=true";
import { default as _91_46_46_46slug_93JzPnKisGOgMeta } from "/var/www/domains/commerce/pages/c/[...slug].vue?macro=true";
import { default as cartInZpyr1k9AMeta } from "/var/www/domains/commerce/pages/cart.vue?macro=true";
import { default as checkout3xMWgx33FvMeta } from "/var/www/domains/commerce/pages/checkout.vue?macro=true";
import { default as previewoMmDNX5cMmMeta } from "/var/www/domains/content/pages/cms/preview.vue?macro=true";
import { default as _91slug_93h3eTgsWXo4Meta } from "/var/www/domains/commerce/pages/collections/[slug].vue?macro=true";
import { default as contact_45us4FWuHnlMWAMeta } from "/var/www/brands/timberland/pages/contact-us.vue?macro=true";
import { default as privacy_45policyHhAT6NlOwmMeta } from "/var/www/brands/timberland/regions/nora/pages/customer-care/privacy-policy.vue?macro=true";
import { default as discountsmcDhmFQtSYMeta } from "/var/www/brands/timberland/pages/discounts.vue?macro=true";
import { default as favoritesgizw0Jr661Meta } from "/var/www/domains/commerce/pages/favorites.vue?macro=true";
import { default as forgot_45passworddgdoaNIK9rMeta } from "/var/www/domains/commerce/pages/forgot-password.vue?macro=true";
import { default as future73d6NUrMWvfjMeta } from "/var/www/brands/timberland/pages/future73.vue?macro=true";
import { default as get_45the_45looksOeDJA27otMeta } from "/var/www/brands/timberland/pages/get-the-look.vue?macro=true";
import { default as gift_45cardSRgQAYx1zyMeta } from "/var/www/domains/commerce/pages/gift-card.vue?macro=true";
import { default as indexIUa7Lmrm8oMeta } from "/var/www/domains/core/pages/healthcheck/feature-flags/index.vue?macro=true";
import { default as mocksQTn8ur5SX5Meta } from "/var/www/domains/core/pages/healthcheck/mocks.vue?macro=true";
import { default as static_45pagescYfh2RJ9owMeta } from "/var/www/domains/core/pages/healthcheck/static-pages.vue?macro=true";
import { default as status6nANMmvYmqMeta } from "/var/www/domains/core/pages/healthcheck/status.vue?macro=true";
import { default as indexDXC9u3mtJFMeta } from "/var/www/domains/commerce/pages/help/[slug]/index.vue?macro=true";
import { default as all_45topics2IdF2pZyt8Meta } from "/var/www/domains/commerce/pages/help/all-topics.vue?macro=true";
import { default as contact_45usOPuksDrnShMeta } from "/var/www/domains/commerce/pages/help/contact-us.vue?macro=true";
import { default as indexlzxkPboVKOMeta } from "/var/www/domains/commerce/pages/help/index.vue?macro=true";
import { default as size_45charthVJJkNVLpwMeta } from "/var/www/brands/timberland/pages/help/size-chart.vue?macro=true";
import { default as help1Y9vn7thDqMeta } from "/var/www/domains/commerce/pages/help.vue?macro=true";
import { default as location_45selectororCIMSoiiyMeta } from "/var/www/domains/commerce/pages/location-selector.vue?macro=true";
import { default as lower_45envsa2TdkLVJtuMeta } from "/var/www/domains/core/pages/lower-envs.vue?macro=true";
import { default as newsletter_45subscriptionEBxkxuoH7JMeta } from "/var/www/brands/timberland/pages/newsletter-subscription.vue?macro=true";
import { default as order_45confirmationNRu6oLRbX0Meta } from "/var/www/domains/commerce/pages/order-confirmation.vue?macro=true";
import { default as order_45detailsRwtOfpUoltMeta } from "/var/www/domains/commerce/pages/order-details.vue?macro=true";
import { default as order_45statusdnReVwYkIqMeta } from "/var/www/domains/commerce/pages/order-status.vue?macro=true";
import { default as gift_45card_45_91slug_93KFxYanwDuhMeta } from "/var/www/domains/commerce/pages/p/[...catalog]/gift-card-[slug].vue?macro=true";
import { default as _91_46_46_46slug_93de6l1mgPjOMeta } from "/var/www/domains/commerce/pages/p/[...slug].vue?macro=true";
import { default as product_45ingredientsb5qkxBnS1FMeta } from "/var/www/brands/timberland/pages/product-ingredients.vue?macro=true";
import { default as reset_45passwordhP0Z1oKL3yMeta } from "/var/www/domains/commerce/pages/reset-password.vue?macro=true";
import { default as return_45labelEopoS5VOoEMeta } from "/var/www/domains/commerce/pages/return-label.vue?macro=true";
import { default as indexq86FzQnihJMeta } from "/var/www/domains/content/pages/sandbox/cms/index.vue?macro=true";
import { default as indexkhPI7q3zYOMeta } from "/var/www/domains/content/pages/sandbox/monetate/index.vue?macro=true";
import { default as dashboardFPRwlP8H6KMeta } from "/var/www/domains/commerce/pages/sandbox/preview/account/dashboard.vue?macro=true";
import { default as profileEnddXePIAwMeta } from "/var/www/domains/commerce/pages/sandbox/preview/account/profile.vue?macro=true";
import { default as checkoutNEpywwyH4vMeta } from "/var/www/domains/commerce/pages/sandbox/preview/checkout.vue?macro=true";
import { default as order_45confirmationrxheZrqFpgMeta } from "/var/www/domains/commerce/pages/sandbox/preview/order-confirmation.vue?macro=true";
import { default as articlesUPT2ffO8ZsMeta } from "/var/www/domains/commerce/pages/search/articles.vue?macro=true";
import { default as indexE4P6LxnUydMeta } from "/var/www/domains/commerce/pages/search/index.vue?macro=true";
import { default as searchqONS1IPIggMeta } from "/var/www/domains/commerce/pages/search.vue?macro=true";
import { default as sign_45ino1WzSX1l1sMeta } from "/var/www/domains/commerce/pages/sign-in.vue?macro=true";
import { default as footerIE4iFHrMAlMeta } from "/var/www/domains/commerce/pages/soci/footer.vue?macro=true";
import { default as frameGOK4ypfhZJMeta } from "/var/www/domains/commerce/pages/soci/frame.vue?macro=true";
import { default as headerWnPkUQwaPWMeta } from "/var/www/domains/commerce/pages/soci/header.vue?macro=true";
import { default as store_45locatorkmWR6VmndsMeta } from "/var/www/domains/commerce/pages/store-locator.vue?macro=true";
import { default as storesCJSNhZmG6iMeta } from "/var/www/brands/timberland/pages/stores.vue?macro=true";
import { default as _91slug_93qvwJBkhS41Meta } from "/var/www/domains/commerce/pages/tag/[slug].vue?macro=true";
import { default as pro_45repository3ntlDImyt0Meta } from "/var/www/brands/timberland/pages/timberlandpro/pro-repository.vue?macro=true";
import { default as pro_45storiesGHbzw3ifW4Meta } from "/var/www/brands/timberland/pages/timberlandpro/pro-stories.vue?macro=true";
import { default as write_45a_45reviewrsL9OQ6nmtMeta } from "/var/www/brands/timberland/pages/write-a-review.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93WSLSvWJwb1Meta?.name ?? "slug",
    path: _91_46_46_46slug_93WSLSvWJwb1Meta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93WSLSvWJwb1Meta || {},
    alias: _91_46_46_46slug_93WSLSvWJwb1Meta?.alias || [],
    redirect: _91_46_46_46slug_93WSLSvWJwb1Meta?.redirect,
    component: () => import("/var/www/domains/content/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _400ZA9i15nQxAMeta?.name ?? "400",
    path: _400ZA9i15nQxAMeta?.path ?? "/400",
    meta: _400ZA9i15nQxAMeta || {},
    alias: _400ZA9i15nQxAMeta?.alias || [],
    redirect: _400ZA9i15nQxAMeta?.redirect,
    component: () => import("/var/www/domains/core/pages/400.vue").then(m => m.default || m)
  },
  {
    name: _401N8cQ5zacU3Meta?.name ?? "401",
    path: _401N8cQ5zacU3Meta?.path ?? "/401",
    meta: _401N8cQ5zacU3Meta || {},
    alias: _401N8cQ5zacU3Meta?.alias || [],
    redirect: _401N8cQ5zacU3Meta?.redirect,
    component: () => import("/var/www/domains/core/pages/401.vue").then(m => m.default || m)
  },
  {
    name: _403Sa7PZc6krdMeta?.name ?? "403",
    path: _403Sa7PZc6krdMeta?.path ?? "/403",
    meta: _403Sa7PZc6krdMeta || {},
    alias: _403Sa7PZc6krdMeta?.alias || [],
    redirect: _403Sa7PZc6krdMeta?.redirect,
    component: () => import("/var/www/domains/core/pages/403.vue").then(m => m.default || m)
  },
  {
    name: _404eylsxcW4sLMeta?.name ?? "404",
    path: _404eylsxcW4sLMeta?.path ?? "/404",
    meta: _404eylsxcW4sLMeta || {},
    alias: _404eylsxcW4sLMeta?.alias || [],
    redirect: _404eylsxcW4sLMeta?.redirect,
    component: () => import("/var/www/domains/core/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _500W3ZJe4VA6ZMeta?.name ?? "500",
    path: _500W3ZJe4VA6ZMeta?.path ?? "/500",
    meta: _500W3ZJe4VA6ZMeta || {},
    alias: _500W3ZJe4VA6ZMeta?.alias || [],
    redirect: _500W3ZJe4VA6ZMeta?.redirect,
    component: () => import("/var/www/domains/core/pages/500.vue").then(m => m.default || m)
  },
  {
    name: about_45us4RruZM0m8HMeta?.name ?? "about-us",
    path: about_45us4RruZM0m8HMeta?.path ?? "/about-us",
    meta: about_45us4RruZM0m8HMeta || {},
    alias: about_45us4RruZM0m8HMeta?.alias || [],
    redirect: about_45us4RruZM0m8HMeta?.redirect,
    component: () => import("/var/www/brands/timberland/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: account8JQgS7IJWSMeta?.name ?? undefined,
    path: account8JQgS7IJWSMeta?.path ?? "/account",
    meta: account8JQgS7IJWSMeta || {},
    alias: account8JQgS7IJWSMeta?.alias || [],
    redirect: account8JQgS7IJWSMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: add_45billing_45addressky4baLy4VQMeta?.name ?? "account-address-add-billing-address",
    path: add_45billing_45addressky4baLy4VQMeta?.path ?? "address/add-billing-address",
    meta: add_45billing_45addressky4baLy4VQMeta || {},
    alias: add_45billing_45addressky4baLy4VQMeta?.alias || [],
    redirect: add_45billing_45addressky4baLy4VQMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/account/address/add-billing-address.vue").then(m => m.default || m)
  },
  {
    name: add_45shipping_45address1u0lx6UGlGMeta?.name ?? "account-address-add-shipping-address",
    path: add_45shipping_45address1u0lx6UGlGMeta?.path ?? "address/add-shipping-address",
    meta: add_45shipping_45address1u0lx6UGlGMeta || {},
    alias: add_45shipping_45address1u0lx6UGlGMeta?.alias || [],
    redirect: add_45shipping_45address1u0lx6UGlGMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/account/address/add-shipping-address.vue").then(m => m.default || m)
  },
  {
    name: indexB51Ugf957PMeta?.name ?? "account-address",
    path: indexB51Ugf957PMeta?.path ?? "address",
    meta: indexB51Ugf957PMeta || {},
    alias: indexB51Ugf957PMeta?.alias || [],
    redirect: indexB51Ugf957PMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/account/address/index.vue").then(m => m.default || m)
  },
  {
    name: addryMI6DLVkOMeta?.name ?? "account-credit-cards-add",
    path: addryMI6DLVkOMeta?.path ?? "credit-cards/add",
    meta: addryMI6DLVkOMeta || {},
    alias: addryMI6DLVkOMeta?.alias || [],
    redirect: addryMI6DLVkOMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/account/credit-cards/add.vue").then(m => m.default || m)
  },
  {
    name: index3o4cqghtCKMeta?.name ?? "account-credit-cards",
    path: index3o4cqghtCKMeta?.path ?? "credit-cards",
    meta: index3o4cqghtCKMeta || {},
    alias: index3o4cqghtCKMeta?.alias || [],
    redirect: index3o4cqghtCKMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/account/credit-cards/index.vue").then(m => m.default || m)
  },
  {
    name: gift_45cardstNcmnAXkqFMeta?.name ?? "account-gift-cards",
    path: gift_45cardstNcmnAXkqFMeta?.path ?? "gift-cards",
    meta: gift_45cardstNcmnAXkqFMeta || {},
    alias: gift_45cardstNcmnAXkqFMeta?.alias || [],
    redirect: gift_45cardstNcmnAXkqFMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/account/gift-cards.vue").then(m => m.default || m)
  },
  {
    name: index4G0LVOmYUUMeta?.name ?? "account",
    path: index4G0LVOmYUUMeta?.path ?? "",
    meta: index4G0LVOmYUUMeta || {},
    alias: index4G0LVOmYUUMeta?.alias || [],
    redirect: index4G0LVOmYUUMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: loyaltye4NDp5ukrDMeta?.name ?? "account-loyalty",
    path: loyaltye4NDp5ukrDMeta?.path ?? "loyalty",
    meta: loyaltye4NDp5ukrDMeta || {},
    alias: loyaltye4NDp5ukrDMeta?.alias || [],
    redirect: loyaltye4NDp5ukrDMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/account/loyalty.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46id_93mxqHszQSfiMeta?.name ?? "account-order-details-id",
    path: _91_46_46_46id_93mxqHszQSfiMeta?.path ?? "order-details/:id(.*)*",
    meta: _91_46_46_46id_93mxqHszQSfiMeta || {},
    alias: _91_46_46_46id_93mxqHszQSfiMeta?.alias || [],
    redirect: _91_46_46_46id_93mxqHszQSfiMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/account/order-details/[...id].vue").then(m => m.default || m)
  },
  {
    name: order_45historyqA4XphvWAYMeta?.name ?? "account-order-history",
    path: order_45historyqA4XphvWAYMeta?.path ?? "order-history",
    meta: order_45historyqA4XphvWAYMeta || {},
    alias: order_45historyqA4XphvWAYMeta?.alias || [],
    redirect: order_45historyqA4XphvWAYMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/account/order-history.vue").then(m => m.default || m)
  },
  {
    name: profileECAuXzYlINMeta?.name ?? "account-profile",
    path: profileECAuXzYlINMeta?.path ?? "profile",
    meta: profileECAuXzYlINMeta || {},
    alias: profileECAuXzYlINMeta?.alias || [],
    redirect: profileECAuXzYlINMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/account/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_935Q04msOnAlMeta?.name ?? "articles-slug",
    path: _91slug_935Q04msOnAlMeta?.path ?? "/articles/:slug()",
    meta: _91slug_935Q04msOnAlMeta || {},
    alias: _91slug_935Q04msOnAlMeta?.alias || [],
    redirect: _91slug_935Q04msOnAlMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93JzPnKisGOgMeta?.name ?? "c-slug",
    path: _91_46_46_46slug_93JzPnKisGOgMeta?.path ?? "/c/:slug(.*)*",
    meta: _91_46_46_46slug_93JzPnKisGOgMeta || {},
    alias: _91_46_46_46slug_93JzPnKisGOgMeta?.alias || [],
    redirect: _91_46_46_46slug_93JzPnKisGOgMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/c/[...slug].vue").then(m => m.default || m)
  },
  {
    name: cartInZpyr1k9AMeta?.name ?? "cart",
    path: cartInZpyr1k9AMeta?.path ?? "/cart",
    meta: cartInZpyr1k9AMeta || {},
    alias: cartInZpyr1k9AMeta?.alias || [],
    redirect: cartInZpyr1k9AMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: checkout3xMWgx33FvMeta?.name ?? "checkout",
    path: checkout3xMWgx33FvMeta?.path ?? "/checkout",
    meta: checkout3xMWgx33FvMeta || {},
    alias: checkout3xMWgx33FvMeta?.alias || [],
    redirect: checkout3xMWgx33FvMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: previewoMmDNX5cMmMeta?.name ?? "cms-preview",
    path: previewoMmDNX5cMmMeta?.path ?? "/cms/preview",
    meta: previewoMmDNX5cMmMeta || {},
    alias: previewoMmDNX5cMmMeta?.alias || [],
    redirect: previewoMmDNX5cMmMeta?.redirect,
    component: () => import("/var/www/domains/content/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93h3eTgsWXo4Meta?.name ?? "collections-slug",
    path: _91slug_93h3eTgsWXo4Meta?.path ?? "/collections/:slug()",
    meta: _91slug_93h3eTgsWXo4Meta || {},
    alias: _91slug_93h3eTgsWXo4Meta?.alias || [],
    redirect: _91slug_93h3eTgsWXo4Meta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/collections/[slug].vue").then(m => m.default || m)
  },
  {
    name: contact_45us4FWuHnlMWAMeta?.name ?? "contact-us",
    path: contact_45us4FWuHnlMWAMeta?.path ?? "/contact-us",
    meta: contact_45us4FWuHnlMWAMeta || {},
    alias: contact_45us4FWuHnlMWAMeta?.alias || [],
    redirect: contact_45us4FWuHnlMWAMeta?.redirect,
    component: () => import("/var/www/brands/timberland/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyHhAT6NlOwmMeta?.name ?? "customer-care-privacy-policy",
    path: privacy_45policyHhAT6NlOwmMeta?.path ?? "/customer-care/privacy-policy",
    meta: privacy_45policyHhAT6NlOwmMeta || {},
    alias: privacy_45policyHhAT6NlOwmMeta?.alias || [],
    redirect: privacy_45policyHhAT6NlOwmMeta?.redirect,
    component: () => import("/var/www/brands/timberland/regions/nora/pages/customer-care/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: discountsmcDhmFQtSYMeta?.name ?? "discounts",
    path: discountsmcDhmFQtSYMeta?.path ?? "/discounts",
    meta: discountsmcDhmFQtSYMeta || {},
    alias: discountsmcDhmFQtSYMeta?.alias || [],
    redirect: discountsmcDhmFQtSYMeta?.redirect,
    component: () => import("/var/www/brands/timberland/pages/discounts.vue").then(m => m.default || m)
  },
  {
    name: favoritesgizw0Jr661Meta?.name ?? "favorites",
    path: favoritesgizw0Jr661Meta?.path ?? "/favorites",
    meta: favoritesgizw0Jr661Meta || {},
    alias: favoritesgizw0Jr661Meta?.alias || [],
    redirect: favoritesgizw0Jr661Meta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passworddgdoaNIK9rMeta?.name ?? "forgot-password",
    path: forgot_45passworddgdoaNIK9rMeta?.path ?? "/forgot-password",
    meta: forgot_45passworddgdoaNIK9rMeta || {},
    alias: forgot_45passworddgdoaNIK9rMeta?.alias || [],
    redirect: forgot_45passworddgdoaNIK9rMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: future73d6NUrMWvfjMeta?.name ?? "future73",
    path: future73d6NUrMWvfjMeta?.path ?? "/future73",
    meta: future73d6NUrMWvfjMeta || {},
    alias: future73d6NUrMWvfjMeta?.alias || [],
    redirect: future73d6NUrMWvfjMeta?.redirect,
    component: () => import("/var/www/brands/timberland/pages/future73.vue").then(m => m.default || m)
  },
  {
    name: get_45the_45looksOeDJA27otMeta?.name ?? "get-the-look",
    path: get_45the_45looksOeDJA27otMeta?.path ?? "/get-the-look",
    meta: get_45the_45looksOeDJA27otMeta || {},
    alias: get_45the_45looksOeDJA27otMeta?.alias || [],
    redirect: get_45the_45looksOeDJA27otMeta?.redirect,
    component: () => import("/var/www/brands/timberland/pages/get-the-look.vue").then(m => m.default || m)
  },
  {
    name: gift_45cardSRgQAYx1zyMeta?.name ?? "gift-card",
    path: gift_45cardSRgQAYx1zyMeta?.path ?? "/gift-card",
    meta: gift_45cardSRgQAYx1zyMeta || {},
    alias: gift_45cardSRgQAYx1zyMeta?.alias || [],
    redirect: gift_45cardSRgQAYx1zyMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/gift-card.vue").then(m => m.default || m)
  },
  {
    name: indexIUa7Lmrm8oMeta?.name ?? "healthcheck-feature-flags",
    path: indexIUa7Lmrm8oMeta?.path ?? "/healthcheck/feature-flags",
    meta: indexIUa7Lmrm8oMeta || {},
    alias: indexIUa7Lmrm8oMeta?.alias || [],
    redirect: indexIUa7Lmrm8oMeta?.redirect,
    component: () => import("/var/www/domains/core/pages/healthcheck/feature-flags/index.vue").then(m => m.default || m)
  },
  {
    name: mocksQTn8ur5SX5Meta?.name ?? "healthcheck-mocks",
    path: mocksQTn8ur5SX5Meta?.path ?? "/healthcheck/mocks",
    meta: mocksQTn8ur5SX5Meta || {},
    alias: mocksQTn8ur5SX5Meta?.alias || [],
    redirect: mocksQTn8ur5SX5Meta?.redirect,
    component: () => import("/var/www/domains/core/pages/healthcheck/mocks.vue").then(m => m.default || m)
  },
  {
    name: static_45pagescYfh2RJ9owMeta?.name ?? "healthcheck-static-pages",
    path: static_45pagescYfh2RJ9owMeta?.path ?? "/healthcheck/static-pages",
    meta: static_45pagescYfh2RJ9owMeta || {},
    alias: static_45pagescYfh2RJ9owMeta?.alias || [],
    redirect: static_45pagescYfh2RJ9owMeta?.redirect,
    component: () => import("/var/www/domains/core/pages/healthcheck/static-pages.vue").then(m => m.default || m)
  },
  {
    name: status6nANMmvYmqMeta?.name ?? "healthcheck-status",
    path: status6nANMmvYmqMeta?.path ?? "/healthcheck/status",
    meta: status6nANMmvYmqMeta || {},
    alias: status6nANMmvYmqMeta?.alias || [],
    redirect: status6nANMmvYmqMeta?.redirect,
    component: () => import("/var/www/domains/core/pages/healthcheck/status.vue").then(m => m.default || m)
  },
  {
    name: help1Y9vn7thDqMeta?.name ?? undefined,
    path: help1Y9vn7thDqMeta?.path ?? "/help",
    meta: help1Y9vn7thDqMeta || {},
    alias: help1Y9vn7thDqMeta?.alias || [],
    redirect: help1Y9vn7thDqMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: indexDXC9u3mtJFMeta?.name ?? "help-slug",
    path: indexDXC9u3mtJFMeta?.path ?? ":slug()",
    meta: indexDXC9u3mtJFMeta || {},
    alias: indexDXC9u3mtJFMeta?.alias || [],
    redirect: indexDXC9u3mtJFMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/help/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: all_45topics2IdF2pZyt8Meta?.name ?? "help-all-topics",
    path: all_45topics2IdF2pZyt8Meta?.path ?? "all-topics",
    meta: all_45topics2IdF2pZyt8Meta || {},
    alias: all_45topics2IdF2pZyt8Meta?.alias || [],
    redirect: all_45topics2IdF2pZyt8Meta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/help/all-topics.vue").then(m => m.default || m)
  },
  {
    name: contact_45usOPuksDrnShMeta?.name ?? "help-contact-us",
    path: contact_45usOPuksDrnShMeta?.path ?? "contact-us",
    meta: contact_45usOPuksDrnShMeta || {},
    alias: contact_45usOPuksDrnShMeta?.alias || [],
    redirect: contact_45usOPuksDrnShMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/help/contact-us.vue").then(m => m.default || m)
  },
  {
    name: indexlzxkPboVKOMeta?.name ?? "help",
    path: indexlzxkPboVKOMeta?.path ?? "",
    meta: indexlzxkPboVKOMeta || {},
    alias: indexlzxkPboVKOMeta?.alias || [],
    redirect: indexlzxkPboVKOMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: size_45charthVJJkNVLpwMeta?.name ?? "help-size-chart",
    path: size_45charthVJJkNVLpwMeta?.path ?? "size-chart",
    meta: size_45charthVJJkNVLpwMeta || {},
    alias: size_45charthVJJkNVLpwMeta?.alias || [],
    redirect: size_45charthVJJkNVLpwMeta?.redirect,
    component: () => import("/var/www/brands/timberland/pages/help/size-chart.vue").then(m => m.default || m)
  }
]
  },
  {
    name: location_45selectororCIMSoiiyMeta?.name ?? "location-selector",
    path: location_45selectororCIMSoiiyMeta?.path ?? "/location-selector",
    meta: location_45selectororCIMSoiiyMeta || {},
    alias: location_45selectororCIMSoiiyMeta?.alias || [],
    redirect: location_45selectororCIMSoiiyMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/location-selector.vue").then(m => m.default || m)
  },
  {
    name: lower_45envsa2TdkLVJtuMeta?.name ?? "lower-envs",
    path: lower_45envsa2TdkLVJtuMeta?.path ?? "/lower-envs",
    meta: lower_45envsa2TdkLVJtuMeta || {},
    alias: lower_45envsa2TdkLVJtuMeta?.alias || [],
    redirect: lower_45envsa2TdkLVJtuMeta?.redirect,
    component: () => import("/var/www/domains/core/pages/lower-envs.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscriptionEBxkxuoH7JMeta?.name ?? "newsletter-subscription",
    path: newsletter_45subscriptionEBxkxuoH7JMeta?.path ?? "/newsletter-subscription",
    meta: newsletter_45subscriptionEBxkxuoH7JMeta || {},
    alias: newsletter_45subscriptionEBxkxuoH7JMeta?.alias || [],
    redirect: newsletter_45subscriptionEBxkxuoH7JMeta?.redirect,
    component: () => import("/var/www/brands/timberland/pages/newsletter-subscription.vue").then(m => m.default || m)
  },
  {
    name: order_45confirmationNRu6oLRbX0Meta?.name ?? "order-confirmation",
    path: order_45confirmationNRu6oLRbX0Meta?.path ?? "/order-confirmation",
    meta: order_45confirmationNRu6oLRbX0Meta || {},
    alias: order_45confirmationNRu6oLRbX0Meta?.alias || [],
    redirect: order_45confirmationNRu6oLRbX0Meta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: order_45detailsRwtOfpUoltMeta?.name ?? "order-details",
    path: order_45detailsRwtOfpUoltMeta?.path ?? "/order-details",
    meta: order_45detailsRwtOfpUoltMeta || {},
    alias: order_45detailsRwtOfpUoltMeta?.alias || [],
    redirect: order_45detailsRwtOfpUoltMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/order-details.vue").then(m => m.default || m)
  },
  {
    name: order_45statusdnReVwYkIqMeta?.name ?? "order-status",
    path: order_45statusdnReVwYkIqMeta?.path ?? "/order-status",
    meta: order_45statusdnReVwYkIqMeta || {},
    alias: order_45statusdnReVwYkIqMeta?.alias || [],
    redirect: order_45statusdnReVwYkIqMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/order-status.vue").then(m => m.default || m)
  },
  {
    name: gift_45card_45_91slug_93KFxYanwDuhMeta?.name ?? "p-catalog-gift-card-slug",
    path: gift_45card_45_91slug_93KFxYanwDuhMeta?.path ?? "/p/:catalog(.*)*/gift-card-:slug()",
    meta: gift_45card_45_91slug_93KFxYanwDuhMeta || {},
    alias: gift_45card_45_91slug_93KFxYanwDuhMeta?.alias || [],
    redirect: gift_45card_45_91slug_93KFxYanwDuhMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/p/[...catalog]/gift-card-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93de6l1mgPjOMeta?.name ?? "p-slug",
    path: _91_46_46_46slug_93de6l1mgPjOMeta?.path ?? "/p/:slug(.*)*",
    meta: _91_46_46_46slug_93de6l1mgPjOMeta || {},
    alias: _91_46_46_46slug_93de6l1mgPjOMeta?.alias || [],
    redirect: _91_46_46_46slug_93de6l1mgPjOMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/p/[...slug].vue").then(m => m.default || m)
  },
  {
    name: product_45ingredientsb5qkxBnS1FMeta?.name ?? "product-ingredients",
    path: product_45ingredientsb5qkxBnS1FMeta?.path ?? "/product-ingredients",
    meta: product_45ingredientsb5qkxBnS1FMeta || {},
    alias: product_45ingredientsb5qkxBnS1FMeta?.alias || [],
    redirect: product_45ingredientsb5qkxBnS1FMeta?.redirect,
    component: () => import("/var/www/brands/timberland/pages/product-ingredients.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordhP0Z1oKL3yMeta?.name ?? "reset-password",
    path: reset_45passwordhP0Z1oKL3yMeta?.path ?? "/reset-password",
    meta: reset_45passwordhP0Z1oKL3yMeta || {},
    alias: reset_45passwordhP0Z1oKL3yMeta?.alias || [],
    redirect: reset_45passwordhP0Z1oKL3yMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: return_45labelEopoS5VOoEMeta?.name ?? "return-label",
    path: return_45labelEopoS5VOoEMeta?.path ?? "/return-label",
    meta: return_45labelEopoS5VOoEMeta || {},
    alias: return_45labelEopoS5VOoEMeta?.alias || [],
    redirect: return_45labelEopoS5VOoEMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/return-label.vue").then(m => m.default || m)
  },
  {
    name: indexq86FzQnihJMeta?.name ?? "sandbox-cms",
    path: indexq86FzQnihJMeta?.path ?? "/sandbox/cms",
    meta: indexq86FzQnihJMeta || {},
    alias: indexq86FzQnihJMeta?.alias || [],
    redirect: indexq86FzQnihJMeta?.redirect,
    component: () => import("/var/www/domains/content/pages/sandbox/cms/index.vue").then(m => m.default || m)
  },
  {
    name: indexkhPI7q3zYOMeta?.name ?? "sandbox-monetate",
    path: indexkhPI7q3zYOMeta?.path ?? "/sandbox/monetate",
    meta: indexkhPI7q3zYOMeta || {},
    alias: indexkhPI7q3zYOMeta?.alias || [],
    redirect: indexkhPI7q3zYOMeta?.redirect,
    component: () => import("/var/www/domains/content/pages/sandbox/monetate/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardFPRwlP8H6KMeta?.name ?? "sandbox-preview-account-dashboard",
    path: dashboardFPRwlP8H6KMeta?.path ?? "/sandbox/preview/account/dashboard",
    meta: dashboardFPRwlP8H6KMeta || {},
    alias: dashboardFPRwlP8H6KMeta?.alias || [],
    redirect: dashboardFPRwlP8H6KMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/sandbox/preview/account/dashboard.vue").then(m => m.default || m)
  },
  {
    name: profileEnddXePIAwMeta?.name ?? "sandbox-preview-account-profile",
    path: profileEnddXePIAwMeta?.path ?? "/sandbox/preview/account/profile",
    meta: profileEnddXePIAwMeta || {},
    alias: profileEnddXePIAwMeta?.alias || [],
    redirect: profileEnddXePIAwMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/sandbox/preview/account/profile.vue").then(m => m.default || m)
  },
  {
    name: checkoutNEpywwyH4vMeta?.name ?? "sandbox-preview-checkout",
    path: checkoutNEpywwyH4vMeta?.path ?? "/sandbox/preview/checkout",
    meta: checkoutNEpywwyH4vMeta || {},
    alias: checkoutNEpywwyH4vMeta?.alias || [],
    redirect: checkoutNEpywwyH4vMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/sandbox/preview/checkout.vue").then(m => m.default || m)
  },
  {
    name: order_45confirmationrxheZrqFpgMeta?.name ?? "sandbox-preview-order-confirmation",
    path: order_45confirmationrxheZrqFpgMeta?.path ?? "/sandbox/preview/order-confirmation",
    meta: order_45confirmationrxheZrqFpgMeta || {},
    alias: order_45confirmationrxheZrqFpgMeta?.alias || [],
    redirect: order_45confirmationrxheZrqFpgMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/sandbox/preview/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: searchqONS1IPIggMeta?.name ?? "search-parent",
    path: searchqONS1IPIggMeta?.path ?? "/search",
    meta: searchqONS1IPIggMeta || {},
    alias: searchqONS1IPIggMeta?.alias || [],
    redirect: searchqONS1IPIggMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: articlesUPT2ffO8ZsMeta?.name ?? "search-articles",
    path: articlesUPT2ffO8ZsMeta?.path ?? "articles",
    meta: articlesUPT2ffO8ZsMeta || {},
    alias: articlesUPT2ffO8ZsMeta?.alias || [],
    redirect: articlesUPT2ffO8ZsMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/search/articles.vue").then(m => m.default || m)
  },
  {
    name: indexE4P6LxnUydMeta?.name ?? "search",
    path: indexE4P6LxnUydMeta?.path ?? "",
    meta: indexE4P6LxnUydMeta || {},
    alias: indexE4P6LxnUydMeta?.alias || [],
    redirect: indexE4P6LxnUydMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/search/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: sign_45ino1WzSX1l1sMeta?.name ?? "sign-in",
    path: sign_45ino1WzSX1l1sMeta?.path ?? "/sign-in",
    meta: sign_45ino1WzSX1l1sMeta || {},
    alias: sign_45ino1WzSX1l1sMeta?.alias || [],
    redirect: sign_45ino1WzSX1l1sMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: footerIE4iFHrMAlMeta?.name ?? "soci-footer",
    path: footerIE4iFHrMAlMeta?.path ?? "/soci/footer",
    meta: footerIE4iFHrMAlMeta || {},
    alias: footerIE4iFHrMAlMeta?.alias || [],
    redirect: footerIE4iFHrMAlMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/soci/footer.vue").then(m => m.default || m)
  },
  {
    name: frameGOK4ypfhZJMeta?.name ?? "soci-frame",
    path: frameGOK4ypfhZJMeta?.path ?? "/soci/frame",
    meta: frameGOK4ypfhZJMeta || {},
    alias: frameGOK4ypfhZJMeta?.alias || [],
    redirect: frameGOK4ypfhZJMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/soci/frame.vue").then(m => m.default || m)
  },
  {
    name: headerWnPkUQwaPWMeta?.name ?? "soci-header",
    path: headerWnPkUQwaPWMeta?.path ?? "/soci/header",
    meta: headerWnPkUQwaPWMeta || {},
    alias: headerWnPkUQwaPWMeta?.alias || [],
    redirect: headerWnPkUQwaPWMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/soci/header.vue").then(m => m.default || m)
  },
  {
    name: store_45locatorkmWR6VmndsMeta?.name ?? "store-locator",
    path: store_45locatorkmWR6VmndsMeta?.path ?? "/store-locator",
    meta: store_45locatorkmWR6VmndsMeta || {},
    alias: store_45locatorkmWR6VmndsMeta?.alias || [],
    redirect: store_45locatorkmWR6VmndsMeta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/store-locator.vue").then(m => m.default || m)
  },
  {
    name: storesCJSNhZmG6iMeta?.name ?? "stores",
    path: storesCJSNhZmG6iMeta?.path ?? "/stores",
    meta: storesCJSNhZmG6iMeta || {},
    alias: storesCJSNhZmG6iMeta?.alias || [],
    redirect: storesCJSNhZmG6iMeta?.redirect,
    component: () => import("/var/www/brands/timberland/pages/stores.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qvwJBkhS41Meta?.name ?? "tag-slug",
    path: _91slug_93qvwJBkhS41Meta?.path ?? "/tag/:slug()",
    meta: _91slug_93qvwJBkhS41Meta || {},
    alias: _91slug_93qvwJBkhS41Meta?.alias || [],
    redirect: _91slug_93qvwJBkhS41Meta?.redirect,
    component: () => import("/var/www/domains/commerce/pages/tag/[slug].vue").then(m => m.default || m)
  },
  {
    name: pro_45repository3ntlDImyt0Meta?.name ?? "timberlandpro-pro-repository",
    path: pro_45repository3ntlDImyt0Meta?.path ?? "/timberlandpro/pro-repository",
    meta: pro_45repository3ntlDImyt0Meta || {},
    alias: pro_45repository3ntlDImyt0Meta?.alias || [],
    redirect: pro_45repository3ntlDImyt0Meta?.redirect,
    component: () => import("/var/www/brands/timberland/pages/timberlandpro/pro-repository.vue").then(m => m.default || m)
  },
  {
    name: pro_45storiesGHbzw3ifW4Meta?.name ?? "timberlandpro-pro-stories",
    path: pro_45storiesGHbzw3ifW4Meta?.path ?? "/timberlandpro/pro-stories",
    meta: pro_45storiesGHbzw3ifW4Meta || {},
    alias: pro_45storiesGHbzw3ifW4Meta?.alias || [],
    redirect: pro_45storiesGHbzw3ifW4Meta?.redirect,
    component: () => import("/var/www/brands/timberland/pages/timberlandpro/pro-stories.vue").then(m => m.default || m)
  },
  {
    name: write_45a_45reviewrsL9OQ6nmtMeta?.name ?? "write-a-review",
    path: write_45a_45reviewrsL9OQ6nmtMeta?.path ?? "/write-a-review",
    meta: write_45a_45reviewrsL9OQ6nmtMeta || {},
    alias: write_45a_45reviewrsL9OQ6nmtMeta?.alias || [],
    redirect: write_45a_45reviewrsL9OQ6nmtMeta?.redirect,
    component: () => import("/var/www/brands/timberland/pages/write-a-review.vue").then(m => m.default || m)
  }
]