import routerOptions0 from "/var/www/node_modules/.pnpm/nuxt@3.11.2_@netlify+blobs@7.3.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset@0.6_stm7kji32kckuty5wqn5cxw72u/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/var/www/app/router.options.ts";
const configRouterOptions = {
  scrollBehaviorType: "smooth",
  hashMode: false
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}