import validate from "/var/www/node_modules/.pnpm/nuxt@3.11.2_@netlify+blobs@7.3.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset@0.6_stm7kji32kckuty5wqn5cxw72u/node_modules/nuxt/dist/pages/runtime/validate.js";
import i18n_45global from "/var/www/domains/core/middleware/i18n.global.ts";
import _15_45to_45canvas_45url_45global from "/var/www/domains/commerce/middleware/15ToCanvasUrl.global.ts";
import dialogs_45global from "/var/www/domains/commerce/middleware/dialogs.global.ts";
import preserve_45query_45global from "/var/www/domains/content/middleware/preserveQuery.global.ts";
import manifest_45route_45rule from "/var/www/node_modules/.pnpm/nuxt@3.11.2_@netlify+blobs@7.3.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset@0.6_stm7kji32kckuty5wqn5cxw72u/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  i18n_45global,
  _15_45to_45canvas_45url_45global,
  dialogs_45global,
  preserve_45query_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}